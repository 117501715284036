<template>
  <fullscreen
    :fullscreen.sync="isFullscreen"
    fullscreen-class="presentation--full-screen"
    :teleport="false"
    @change="handleFullscreenChange"
  >
    <template v-if="!isCollaborativeDocument && fileType !=='file'">
      <b-overlay
        :show="isLoading"
        class="pdf-reader-container"
        rounded="sm"
        spinner-type="grow"
      >
        <pdf
          ref="pdf-reader"
          class="pdf-reader"
          :src="media.url"
          type="application/pdf"
          :page="currentPage"
          :options="{ isEvalSupported: false }"
          @loaded="handleDocumentLoaded"
          @num-pages="handleNumPages"
          @page-loaded="handlePageLoaded"
        />
      </b-overlay>

      <!-- barra de botones -->
      <div class="d-flex justify-content-center w-100 barra mt-1">
        <div class="d-flex align-items-center">
          <b-button
            variant="link"
            class="muted"
            :disabled="!canGoToPreviousPage"
            @click="goToPreviousPage"
          >
            <chevron-left-icon />
          </b-button>
          <p class="text-muted m-0 d-flex align-items-center">
            {{ $t('media.page') }} {{ currentPage }} / {{ numPages }}
          </p>
          <b-button
            variant="link"
            class="btn-icon"
            :disabled="!canGoToNextPage"
            @click="goToNextPage"
          >
            <chevron-right-icon />
          </b-button>
        </div>
        <b-button variant="link" class="btn-icon h2 font-weight-bolder text-muted fullscreen">
          <feather-icon
            icon="Maximize2Icon"
            size="22"
            class="pt-25"
            @click="toggleFullscreen"
          />
        </b-button>
      </div>
    </template>
    <template v-else>
      <div class="open">
        <safe-img
          :src="previewPlaceholder"
          alt="preview photo"
          class="preview-image w-100"
          style="height:110%"
        />
        <div v-if="fileType==='file'" class="h-100 w-100 open--container">
          <b-button
            variant="outline-light"
            class="open--file"
            :href="media.url"
            target="_blank"
          >
            {{ $t('media.download-file') }}
          </b-button>
        </div>
        <div v-if="isCollaborativeDocument" class="h-100 w-100 open--container">
          <b-button variant="outline-light" class="open--file" :to="collaborativeLocation">
            {{ $t('media.edit-collaboratively-action') }}
          </b-button>
        </div>
        <!--         <b-embed :src="media.url" allowfullscreen class="video-border" />
 -->
      </div>
    </template>
  </fullscreen>
</template>

<script>
import {
  BOverlay, BButton, BEmbed, BFormInput,
} from 'bootstrap-vue';
import { ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons';
import pdf from 'vue-pdf';
import { component as fullscreen } from 'vue-fullscreen';
import { getImageResource } from '@/@core/utils/image-utils';
import DocPlaceholder from '@/assets/images/placeholders/light/banner_doc.png';
import PresentationPlaceholder from '@/assets/images/placeholders/light/banner_presentacion.png';
import SpreadsheetPlaceholder from '@/assets/images/placeholders/light/banner_excel.png';
import FilePlaceholder from '@/assets/images/placeholders/light/banner_file.png';
import SafeImg from '@core/components/safe-img/SafeImg.vue';

export default {
  name: 'MediaPresentationRenderer',
  components: {
    BOverlay,
    BButton,
    ChevronRightIcon,
    ChevronLeftIcon,
    fullscreen,
    pdf,
    SafeImg,
    BEmbed,
    BFormInput,
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingPage: false,
      numPages: 1,
      currentPage: 1,
      isFullscreen: false,
    };
  },
  computed: {
    canGoToNextPage() {
      // sroll to top of the page
      this.scrollToTop();
      return !this.isLoading && !this.isLoadingPage && this.numPages && this.currentPage < this.numPages;
    },
    canGoToPreviousPage() {
      // sroll to top of the page
      this.scrollToTop();
      return !this.isLoading && !this.isLoadingPage && this.currentPage > 1;
    },
    previewPlaceholder() {
      switch (this.fileType) {
        case 'xlsx':
          return SpreadsheetPlaceholder;
        case 'pptx':
          return PresentationPlaceholder;
        case 'docx':
          return DocPlaceholder;
        default:
          return FilePlaceholder;
      }
    },
    isCollaborativeDocument() {
      if (this.media.url.includes('.docx') || this.media.url.includes('.doc') || this.media.url.includes('.pptx') || this.media.url.includes('.xlsx') || this.media.isCollaborative) {
        return true;
      }
      return false;
    },
    collaborativeLocation() {
      return {
        name: 'collaborative-documents',
        params: { id: this.media.key, media: this.media, communityId: this.$route.params.communityId },
      };
    },
    fileType() {
      if (this.media.url.includes('.docx')) {
        return 'docx';
      }
      if (this.media.url.includes('.pptx')) {
        return 'pptx';
      }
      if (this.media.url.includes('.xlsx')) {
        return 'xlsx';
      } if (this.media.url.includes('.pdf')) {
        return 'xlsx';
      } if (this.media.mediaType === 'file') {
        return 'file';
      }
      return 'xlsx';
    },
  },
  created() {
    window.addEventListener('keyup', this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    handleKeyUp({ key }) {
      switch (key) {
        case 'ArrowRight':
          if (this.canGoToNextPage) {
            this.goToNextPage();
          }
          break;
        case 'ArrowLeft':
          if (this.canGoToPreviousPage) {
            this.goToPreviousPage();
          }
          break;
        default:
          break;
      }
    },
    handleDocumentLoaded() {
      this.isLoading = false;
    },
    handleNumPages(numPages) {
      if (numPages) {
        this.numPages = numPages;
      }
    },
    handlePageLoaded() {
      this.isLoadingPage = false;
    },
    goToNextPage() {
      this.currentPage += 1;
    },
    goToPreviousPage() {
      this.currentPage -= 1;
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
    async handleFullscreenChange(isFullscreen) {
      if (isFullscreen) {
        await this.$nextTick();
        const { offsetWidth, offsetHeight, scrollHeight } = this.$refs['pdf-reader'].$el;

        // set new width that respects aspect ratio.
        this.$refs['pdf-reader'].$el.style.width = `${(offsetWidth / scrollHeight) * offsetHeight}px`;
      } else {
        this.$refs['pdf-reader'].$el.style.width = null;
      }
    },
    getImageResource,
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: for smooth scrolling
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include';
.presentation--full-screen {
  display: flex;
  flex-direction: column;
  .pdf-reader-container {
    overflow-y: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .pdf-reader {
    flex-grow: 1;
    margin: auto;
  }
}
.form-control {
  width: 80px;
  max-width: 80px;
}
.barra {
  background-color: rgb(87, 87, 87);
  border-radius: 8px;
  z-index: 1;
}
.fullscreen {
  position: absolute;
  right: 40px;
}
.open {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &--container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(32,32,32,0.6);
    border-radius:12px;
  }
  &--container:hover {
    opacity: 100;
    transition: opacity 0.5s;
  }
}
.preview-image {
  @import '~@core/scss/base/bootstrap-include';
  max-height: 400px;
  object-fit: cover;
  width: 80vw;
  @include media-breakpoint-up(md) {
    width: auto;
  }
}
</style>
